'use client';

import { useEffect } from 'react';
import Image from 'next/image';
import { useTranslations } from 'next-intl';

import Button from '../src/components/Button';
import Typography from '../src/components/Typography';

const Error = ({ error, reset }) => {
  useEffect(() => {
    if (window.newrelic) {
      window.newrelic.noticeError(error);
    }
  }, [error]);

  // translations
  const t = useTranslations('Error');

  return (
    <main className="wrapper error-page" tabIndex="-1" id="main">

      <section className="text-section">
        <Typography variant="heading-900" addClasses="error-code">
          {t('500.headline')}
        </Typography>
        <Typography variant="heading-700" addClasses="error-description">
          {t('500.subhead')}
        </Typography>
        <Typography variant="body" addClasses="error-body">
          {t('500.body')}
        </Typography>
        <div className="error-cta-wrapper">
          <Button
            ariaLabel={t('500.ctas.reload.ariaLabel')}
            click={() => { reset(); }}
            label={t('500.ctas.reload.label')}
            theme="dark"
            trackingDetails={{ contentName: '500 page', contentPiece: 'reload this page button' }}
            variant="secondary"
          />
          <Button
            ariaLabel={t('500.ctas.home.ariaLabel')}
            href="/"
            label={t('500.ctas.home.label')}
            theme="light"
            trackingDetails={{ contentName: '500 page', contentPiece: 'home button' }}
            variant="secondary"
          />
        </div>
      </section>
      <section className="image-section">
        <div className="image-wrapper">
          <Image src="/images/OhCrumbs.png" alt={t('500.image.alt')} fill priority="true" />
        </div>
      </section>

    </main>
  );
};

export default Error;
